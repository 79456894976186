import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive, slider } from './Slider.constant';
import './Slider.css';

const Slider = () => {
    return (
        <section className="container-fluid slider-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="display-5 text-center text-white">Our Current Location</h1>
                    </div>
                    <div className="col-12">
                        <h5 className="text-center t-c-yellow">Explore Jaipur with us</h5>
                    </div>
                    <div className="col-12">
                        <Carousel responsive={responsive}>
                            {slider.map((item, key) => (
                                <div key={key} className="slide-option">
                                    <img src={item} alt="" />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section >
    )
};

export default Slider;