import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from '../../assets/logo/Logo-cropped.png'
import "bootstrap-icons/font/bootstrap-icons.css";
import './Header.css';

const Header = () => {

    const [scrolled, setScrolled] = useState(false);
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrolled(window.scrollY > 0)
        })
    }, [window.scrollY])

    useEffect(() => {
        onTop();
    }, [routePath]);
    return (
        <header className={scrolled ? 'container-fluid sticky' : 'container-fluid'}>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 text-md-start text-center">
                        <Link to="/"><img src={Logo} className="logo-image" alt="" /></Link>
                    </div>
                    <div className="col-md-9 text-md-end text-center text-white header-info">
                        <i className="bi bi-envelope-at-fill"></i> <span className="me-3"><a href="mailto:reservations@thevacationpod.com">reservations@thevacationpod.com</a></span>
                        <br className="display-block-none"/>
                        <i className="bi bi-telephone-fill"></i> <span><a href="tel:+919310685599">+91 9310685599</a></span>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;