
import axios from 'axios';
import { URL } from '../../config';

export const FORM_SUBMISSION_STATUS = {
    passed: 'passed',
    failed: 'failed'
}

export const changeHandler = (e, formData, setFormData) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
}

export const submitHandler = (e, formData, setFormData, setFormResponse) => {
    e.preventDefault();

    if (formData.fname && formData.lname && formData.phone && formData.email && formData.message) {

        axios.post(URL, formData).then(res => {
            setFormResponse({
                status: FORM_SUBMISSION_STATUS.passed,
                message: 'Form has been submitted'
            })
        }).catch(err => {
            setFormResponse({
                status: FORM_SUBMISSION_STATUS.failed,
                message: err.message
            })
        }).finally(() => {
            setFormData(() => ({
                fname: '',
                lname: '',
                phone: '',
                email: '',
                message: ''
            }));
        });
    } else {
        setFormResponse({
            status: FORM_SUBMISSION_STATUS.failed,
            message: 'All fields are mandatory'
        })
    }

}