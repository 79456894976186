import React from "react";
import { Link } from "react-router-dom";
import { MEDIA_LINKS } from '../../config';
import fb from "../../assets/logo/fb.svg"
import instagram from "../../assets/logo/instagram.svg"
import youtube from "../../assets/logo/youtube.svg"
import Whatsapp from '../../assets/icons-whatsapp.svg';
import "bootstrap-icons/font/bootstrap-icons.css";
import './Footer.css';

const Footer = () => {
    return (
        <footer className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-12 footer">
                        <div className="row">
                            <div className="col-md-8">
                                <ul className="footer-links">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                    <li><Link to="/terms-and-condition">Terms and conditions</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="footer-links text-md-end text-center">
                                    <li>
                                        <a target="_blank" href={MEDIA_LINKS.FB_LINK}><img src={fb} alt="facebook" /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href={MEDIA_LINKS.INSTA_LINK}><img src={instagram} alt="instagram" /></a>
                                    </li>
                                    <li>
                                        <a target="_blank" href={MEDIA_LINKS.YT_LINK}><img src={youtube} alt="youtube" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-white text-center pt-3">
                        <h6>2024 © All rights reserved.</h6>
                    </div>
                    <div className="whatsapp-icon">
                        <a target="_blank" href={MEDIA_LINKS.WHATSAPP_LINK}><img src={Whatsapp} alt="whatsapp" /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;