import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive, testimonials } from './Testimonial.constants';
import GoogleReview from '../../assets/googleReview.png';
import './Testimonial.css';


const Testimonial = () => {
    return (
        <section className="container-fluid testimonial-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="display-5 text-center text-white">What our customers say</h1>
                    </div>
                    <div className="col-12">
                        <Carousel responsive={responsive}>
                            {testimonials.map(item =>
                                <div key={item.id} className="testimonial-wrapper">
                                    <div className="col-12 single-testimonial">
                                        <div className="row">
                                            <div className="col-12">
                                                <img src={item.avatar} alt={item.name} /><span>{item.name}</span>
                                            </div>
                                            <div className="col-12 mt-3 testimonial-text">
                                                {item.text}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Carousel>
                    </div>
                    <div className="col-12 text-center mt-3">
                        <img src={GoogleReview} width={250} alt="google review"/>
                    </div>
                </div>
            </div>
        </section >
    )
};

export default Testimonial;