import React from "react";
import ContactForm from './ContactForm';
import { MEDIA_LINKS } from '../../config';
import './Contact.css';
import HomeIcon from '../../assets/collab.png';

const Contact = () => {
    return (
        <section className="container-fluid">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-6 offset-md-3 collabotate-box">
                                <div className="row">
                                    <div className="col-md-7 text-md-start text-center order-md-1 order-2">
                                        <h4><strong>We're here for you</strong></h4>
                                        <h5>Become a partner to list your property</h5>
                                        <a id="contact-form" target="_blank" href={MEDIA_LINKS.WHATSAPP_LINK} className="collaborate-button">Collaborate with us</a>
                                    </div>
                                    <div className="col-md-5 text-md-end text-center order-md-2 order-1 mb-3">
                                        <img src={HomeIcon} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ContactForm></ContactForm>
                </div>
            </div>
        </section >
    )
};

export default Contact;